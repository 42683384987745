import { addChatHandler, getChatHash, removeChatHandler } from '@libs/services';
import { useAuthorize, useCheckBrowser } from '@libs/utils';
import { type AppDispatch, checkEmailThunk, type RootState } from '@store/store';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, ScrollRestoration, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { httpClient } from '@configs/httpclient.config';
import { useEffect, useRef, useState } from 'react';
import { YandexMetrica } from '@libs/ym/YandexMetrica';
import { unwrapResult } from '@reduxjs/toolkit';
import { IS_INVESTORPRO_AUTHORIZED_PARAMETER, IS_INVESTORPRO_REDIRECT_PARAMETER } from '@libs/utils/hooks/useAuthorize';
import useScrollTop from '@libs/utils/hooks/useScrollTop';
import { setShowOnboardingModal } from '@store/store/slices/user.slice';
import { OnboardingModal } from '@libs/components/overlay/OnboardingModal';
import type { ProductReq } from '@libs/types';
import { Toast } from 'primereact/toast';
import { getUnreadNotificationsCountThunk } from '@store/store/thunk/notification.thunk';

import { Loader } from '../../loaders/Loader';
import { Header } from '../../pageBlocks/Header';


export const bodyReq: ProductReq = {
    pageable: {
        pageSize: 10,
        pageNumber: 0,
        sortField: 'order',
        sortOrder: 'asc',
    },
    statuses: ['ACTIVE', 'SOON_AVAILABLE', 'ARCHIVED'],
};

type chatData = {
    uuid: string;
    hash: string;
    first_name?: string;
    last_name?: string;
    phone?: string;
};

export const Layout = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { isAuthorized, token, tokenData, logoutHandler, loginHandler, loginInProgress } = useAuthorize();
    const [isCheckedEmail, setCheckedEmail] = useState<boolean>(false);
    const [navigatePath, setNavigatePath] = useState<string>();
    const toastRef = useRef<Toast>(null);
    const { isUpdateBrowser } = useCheckBrowser();

    const location = useLocation();

    useEffect(() => {
        const emailToken = searchParams.get('checkemail');

        if (window.location.pathname !== '/personal-account/session-expired' && emailToken) {
            setCheckedEmail(true);
            dispatch(checkEmailThunk(emailToken))
                .then((res) => {
                    const result = unwrapResult(res);
                    setCheckedEmail(false);

                    if (result?.status?.errorCode) {
                        setNavigatePath('/personal-account/not-valid-link');
                    } else {
                        setNavigatePath('/personal-account?confirmed=true');
                    }
                })
                .catch((e) => {
                    console.log(e);
                    setCheckedEmail(false);
                    setNavigatePath(`/personal-account/session-expired${window.location.search}`);
                });
        }

        if (isAuthorized) {
            dispatch(getUnreadNotificationsCountThunk());
        }
    }, []);

    useEffect(() => {
        if (navigatePath) {
            navigate(navigatePath);
        }
    }, [navigatePath]);

    useEffect(() => {
        if (location.hash.includes('error')) {
            const newPath = location.pathname + location.search;
            navigate(newPath, { replace: true });
        }

        if (!location.hash.includes('error') && searchParams.get('showOnboarding') === 'true') {
            dispatch(setShowOnboardingModal(true));
        }

        if (isAuthorized) {
            dispatch(getUnreadNotificationsCountThunk());
        }
    }, [location, navigate]);

    useEffect(() => {
        const isInvestorProRedirect: boolean = location.search
            .toLowerCase()
            .includes(IS_INVESTORPRO_REDIRECT_PARAMETER.toLowerCase());
        const isInvestorProAuthorized: boolean = location.search
            .toLowerCase()
            .includes(IS_INVESTORPRO_AUTHORIZED_PARAMETER.toLowerCase());

        if (isInvestorProRedirect) {
            if (isAuthorized && !isInvestorProAuthorized) {
                logoutHandler();
            } else if (!isAuthorized && isInvestorProAuthorized) {
                loginHandler();
            }
        }
    }, [isAuthorized]);

    useEffect(() => {
        if (isUpdateBrowser && !sessionStorage.getItem('showedBrowserNotification')) {
            toastRef.current?.show({
                severity: 'warn',
                summary: 'Вы используете неактуальную версию браузера!',
                detail: 'Ваша версия браузера не поддерживает современные технологии, поэтому некоторые страницы могут отображаться некорректно. Предлагаем Вам обновить браузер до актуальной версии.',
                sticky: true,
            });
            sessionStorage.setItem('showedBrowserNotification', 'true');
        }
    }, [isUpdateBrowser]);

    if (token && !loginInProgress) {
        httpClient.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    if (httpClient.defaults.headers.common.Authorization && loginInProgress) {
        httpClient.defaults.headers.common.Authorization = '';
    }

    const isCommonLoading = useSelector((state: RootState) => state.loading.isLoading);
    const isAlgopackLoading = useSelector((state: RootState) => state.algopack.isLoading);
    const isUserDataLoading = useSelector((state: RootState) => state.user.isLoading);
    const isChartsLoading = useSelector((state: RootState) => state.priceCenter.charts.isLoading);
    const isDataUploadLoading = useSelector((state: RootState) => state.priceCenter.dataUpload.isLoading);
    const isProductLoading = useSelector((state: RootState) => state.product.isLoading);

    const isLoading =
        isCommonLoading ||
        isAlgopackLoading ||
        isUserDataLoading ||
        isChartsLoading ||
        isDataUploadLoading ||
        isProductLoading ||
        isCheckedEmail;

    useEffect(() => {
        /*
         * todo: Dirty hack
         * */
        localStorage.removeItem('webchat-channel_377e8bf-uuid');
        localStorage.removeItem('webchat-channel_3c68cbb-uuid');
        localStorage.removeItem('webchat-channel_395750d-uuid');

        if (isAuthorized) {
            getChatHash()
                .then((value: any) => {
                    const data: chatData = {
                        uuid: value.data.data.uuid,
                        hash: value.data.data.hash,
                        first_name: value.data?.data?.firstName,
                        last_name: value.data?.data?.lastName,
                    };

                    window.__WebchatUserCallback = () => {
                        if (data.uuid && data.first_name && data.last_name) {
                            //console.log('__WebchatUserCallback fully authorized', data);

                            return data;
                        } else {
                            const data: chatData = {
                                uuid: value.data.data.uuid,
                                hash: value.data.data.hash,
                            };

                            //console.log('__WebchatUserCallback partially authorized', data);

                            return data;
                        }
                    };

                    if (data.uuid && data.first_name && data.last_name) {
                        removeChatHandler();
                        addChatHandler('fully_authorized');
                    } else {
                        removeChatHandler();
                        addChatHandler('partially_authorized');
                    }
                })
                .catch((err: any) => {
                    console.log(err);
                });
        } else {
            //console.log('__WebchatUserCallback unauthorized');
            removeChatHandler();
            addChatHandler('unauthorized');
        }

        return () => {};
    }, [isAuthorized]);

    useScrollTop(500);

    return (
        <YandexMetrica>
            <Header />
            <Toast ref={toastRef} />
            {(!searchParams.get('checkemail') || window.location.pathname === '/personal-account/session-expired') && (
                <Outlet />
            )}
            <ScrollRestoration />
            <Loader isLoading={isLoading} />
            <OnboardingModal />
        </YandexMetrica>
    );
};
